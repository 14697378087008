








import PageTitle from '@/components/PageTitle.vue'

import ServicesCart from 'piramis-base-components/src/plugins/AdminTemplate/pages/services-cart.vue'

import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component({
  components: {
    PageTitle,
    ServicesCart
  }
})
export default class BuyChatLicense extends Vue {

}
